import React, { useEffect, useState, FC, useMemo } from 'react';
import { navigate } from 'gatsby';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { styled, useTheme } from '@mui/material/styles';

import SEO from 'src/components/SEO';
import Layout from 'src/template/layout';
import { getQueryVariable } from 'src/utils';

interface Props {
  location: Location;
}

const SectionHeading2 = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  marginBottom: 40,
  lineHeight: 1,

  [theme.breakpoints.up('md')]: {
    marginBottom: 75,
    fontSize: 60,
  },
})) satisfies typeof Typography;

const JobFilterWrapper = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  display: 'block',
  padding: 0,

  '& li': {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#f8f8f8',
    borderRadius: 50,
    fontFamily: ['Poppins', 'Pretendard', 'Noto Sans KR', 'system-ui', 'sans-serif'].join(','),
    fontSize: 14,
    marginBottom: 12,
    marginRight: 12,
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      padding: '20px 40px',
      marginBottom: 24,
      marginRight: 24,
    },
  },
}));

const Team = styled('li')<{ selected: boolean }>(
  ({ selected }) =>
    selected && {
      backgroundColor: '#00dd99 !important',
      color: '#ffffff',
    }
);

const StyledTableContainer = styled(TableContainer)({
  minHeight: '100vh',
});

const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
  '& td': {
    paddingTop: 30,
    paddingBottom: 30,
    border: 0,
    fontFamily: ['Poppins', 'Pretendard', 'Noto Sans KR', 'system-ui', 'sans-serif'].join(','),
    fontSize: 24,
  },
  '& th': {
    border: 0,
    fontSize: 24,
    color: '#00dd99',
  },
});

const PlusIcon = styled(AddIcon)({
  color: '#00dd99',
});

const Background = styled('div')(({ theme }) => ({
  backgroundImage: "url('/backgrounds/img-bg-type-02.svg')",
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '80vw',
  left: '20vw',
  position: 'absolute',
  height: '1736px',
  zIndex: '-1',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const cmpRegex = (a: string, b: string, dir: 1 | -1) => {
  const symbolRegex = /^[^\w\s]/;
  const hasSymbolA = symbolRegex.test(a);
  const hasSymbolB = symbolRegex.test(b);
  return (Number(hasSymbolB) - Number(hasSymbolA)) * dir;
};

const cmp = (a: string, b: string, dir: 1 | -1) => {
  const diff = cmpRegex(a, b, dir);
  if (diff !== 0) {
    return diff;
  }
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};

// markup
const JobsPage: FC<Props> = ({ location }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const leverData = useStaticQuery<{
    allLever: {
      teams: string[];
      nodes: {
        categories: {
          team: string;
          location: string;
          department: string;
          commitment: string;
        };
        id: string;
        text: string;
        lever_id: string;
      }[];
    };
  }>(graphql`
    query {
      allLever(filter: { categories: { department: { eq: "Hyperconnect" } } }) {
        teams: distinct(field: categories___team)
        nodes {
          categories {
            team
            location
            department
            commitment
          }
          id
          text
          lever_id
        }
      }
      applyStepImg: file(relativePath: { eq: "jobsPage/img-process@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const {
    allLever: { teams, nodes },
  } = leverData;
  const sortedTeams = useMemo(
    () => teams.sort((a, b) => cmp(a, b, 1)),
    [teams]
  );
  const sortedNodes = useMemo(() => {
    const symbolRegex = /^[^\w\s]/;
    return [...nodes].sort(
      (
        { categories: { team: teamA }, text: textA },
        { categories: { team: teamB }, text: textB }
      ) => {
        const diff = cmpRegex(textA, textB, -1);
        if (diff !== 0) {
          return diff;
        }
        return cmp(teamA, teamB, 1);
      }
    );
  }, [nodes]);

  const [selectedTeam, setTeam] = useState('');
  const [positionList, setPositionList] = useState(nodes);

  const handleClickRow = (id: string): void => {
    navigate(`/job/${id}`);
  };

  const handleClickTeam = (team: string): void => {
    if (!team) {
      navigate('/jobs');
      return;
    }

    navigate(`/jobs?team=${team}`);
  };

  useEffect(() => {
    const team = getQueryVariable(location.search, 'team');
    if (team) {
      setTeam(team);
      const filteredPosition = sortedNodes.filter(
        node => node.categories.team === team
      );
      setPositionList(filteredPosition);
    } else {
      setPositionList(sortedNodes);
      setTeam('');
    }
  }, [location, sortedNodes]);

  return (
    <Layout>
      <Background />
      <section>
        <Container>
          <SectionHeading2 variant="h2">
            HELLO, IS IT YOU
            <br />
            <b>WE’RE LOOKING FOR?</b>
          </SectionHeading2>

          <JobFilterWrapper>
            <Team selected={!selectedTeam} onClick={() => handleClickTeam('')}>
              ALL
            </Team>
            {sortedTeams.map(team => (
              <Team
                selected={selectedTeam === team}
                onClick={() => handleClickTeam(team)}
                key={team}>
                {team}
              </Team>
            ))}
          </JobFilterWrapper>
          {mdUp ? (
            <StyledTableContainer>
              <Table>
                <TableBody>
                  {positionList.map(node => (
                    <StyledTableRow
                      key={node.lever_id}
                      hover
                      onClick={() => handleClickRow(node.lever_id)}>
                      <TableCell component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
                        {node.categories.team}
                      </TableCell>
                      <TableCell>{node.text}</TableCell>
                      <TableCell>{node.categories.location}</TableCell>
                      <TableCell>{node.categories.commitment}</TableCell>
                      <TableCell>
                        <PlusIcon />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          ) : (
            /* mobile list */
            <List component="nav" aria-label="main mailbox folders">
              {positionList.map(node => (
                <ListItem
                  key={node.lever_id}
                  button
                  onClick={() => handleClickRow(node.lever_id)}>
                  <ListItemText
                    primary={node.categories.team}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary">
                          {node.text} |{' '}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary">
                          {node.categories.location} |{' '}
                          {node.categories.commitment}
                        </Typography>
                      </>
                    }
                  />
                  <ListItemIcon>
                    <PlusIcon />
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default JobsPage;


export const Head = () => {
  return (
    <SEO
      title={'Jobs'}
      description={'MORE CONNECTED THAN EVER LET’S BE HYPERCONNECTED!'}
    />
  )
}